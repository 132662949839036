import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import { useMetaOverride } from '../lib/hoc/useMeta';

const SignupRoute = (props) => {
  const post = useMetaOverride('signup', props.data.markdownRemark);
  const signupHtml = _.get(post, 'frontmatter.description');
  return (
    <div 
      type='text/javascript'
      dangerouslySetInnerHTML={{
        __html: signupHtml,
      }}
    />
  );
}

export default SignupRoute;

export const SignupRouteQuery = graphql`
  query SignupRoute($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
      }
    }
  }
`;
